@import 'typography';

*,
*::before,
*::after {
    box-sizing: inherit;
    background-repeat: no-repeat;
}

*::before,
*::after {
    text-decoration: inherit;
    vertical-align: inherit;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

td, tr, th {
    vertical-align: middle;
}

button,
input,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a, a:visited, a:hover, a:active {
    color: inherit;
    text-decoration: none;
}

a, button {
    cursor: pointer;
}

p {
    margin-bottom: 1em;
}

html {
    box-sizing: border-box;
    height: 100%;
    line-height: 1.5;
    word-break: break-word;
}

body {
    height: 100%;
    min-height: 100%;
    margin: 0;
}

// Remove the list style on navigation lists in all browsers
nav ol,
nav ul {
    list-style: none;
}

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code, kbd, pre, samp {
    font-family:
        /* macOS 10.10+ */ Menlo,
        /* Windows 6+ */ Consolas,
        /* Android 4+ */ 'Roboto Mono',
        /* Ubuntu 10.10+ */ 'Ubuntu Monospace',
        /* KDE Plasma 5+ */ 'Noto Mono',
        /* KDE Plasma 4+ */ 'Oxygen Mono',
        /* Linux/OpenOffice fallback */ 'Liberation Mono',
        /* fallback */ monospace;

    font-size: 1rem;
}

small {
    font-size: 80%;
}

/**
 * Collapse border spacing in all browsers.
 */
table {
    border-collapse: collapse;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers.
 */
[aria-disabled='true'],
[disabled] {
    cursor: not-allowed;
}
