@use '@angular/material' as mat;

@import 'mixins';
@import 'normalize';
@import 'material-theme';
@import 'toastr';
@import 'variables';
@import 'uppy';

body {
    overflow-x: hidden;
}

.text {

    &-error {
        color: mat.get-color-from-palette(mat.$red-palette, 500) !important;
    }

    &-success {
        color: mat.get-color-from-palette(mat.$green-palette, 500) !important;
    }

    &-warning {
        color: $warning !important;
    }
}

.pointer {
    cursor: pointer;
}

.first-letter-uppercase::first-letter {
    text-transform: capitalize;
}

.mat-mdc-card-actions {
    gap: 8px;
}

.reverse-row {
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: end !important;
}

.flex-spacer {
    flex-grow: 1;
}

.split-16 {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
}

.container {
    @include container();
}

.pre-wrap {
    white-space: pre-wrap !important;
}

.mat-horizontal-stepper-header-container {
    overflow-x: auto;
}

mat-spinner.mat-mdc-progress-spinner, mat-progress-spinner.mat-mdc-progress-spinner {

    &.inline {
        display: inline-block;
        margin: 0;
    }
}

button[color='primary'][mat-flat-button],
button[color='primary'][mat-raised-button] {

    .mat-mdc-progress-spinner circle {
        stroke: white;
    }
}

button.link {
    padding: 0;
    border: none;
    border-bottom: 1px solid #444;
    outline: none;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
}

a.link {
    transition: opacity 0.5ms ease-out;
    text-decoration: underline;

    &:hover {
        opacity: 0.8;
    }
}

.link-color {
    color: #2323f0;
}

dl {

    dt {
        margin-top: 0.5em;
        font: 500 14px / 20px $font-family;

        &:first-of-type {
            margin-top: 0;
        }
    }
}

form {

    input {
        width: 100%;
    }

    mat-form-field {
        width: 100%;
    }

    > mat-form-field + mat-form-field {
        margin-top: 0.75em;
    }

    .form-row {

        @media (min-width: 480px) {
            display: flex;
            flex-direction: row;
            justify-content: center;

            mat-form-field {
                margin-right: 16px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .form-header {
        margin-top: 1em;
        margin-bottom: 0.5em;
        font-weight: 500;
    }
}

.mdc-button__label {
    // https://github.com/angular/components/issues/8842
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.mat-button-leading-icon > .mdc-button__label, .button-leading-icon {
    // See https://m2.material.io/components/buttons#specs
    // Do not add when button contains mat-icon in app-submitting

    .mat-icon {
        @include mat-icon-size(18px);
    }

    .mat-icon, mat-progress-spinner {
        margin-right: 8px;
        margin-left: -4px;
    }
}

.table {
    width: 100%;

    mat-row, tr {

        &:hover, &:focus-within {
            background-color: rgba(0, 0, 0, 0.035);
            cursor: pointer;

            .show-on-hover {
                opacity: 1;
            }
        }

        mat-cell, td {
            cursor: text;

            &.column-small-as-possible {
                width: 1px;
                white-space: nowrap;
            }

            &.show-on-hover {
                opacity: 0;
            }
        }
    }

    &.responsive-table {
        min-width: 150px;

        &.mat-mdc-table {
            overflow: auto;
        }

        .mobile-label {
            display: none;
        }

        @media (max-width: 959px) {
            $cell-padding: 0.5em;

            .mobile-label {
                display: block;
                font-weight: 500;
            }

            .mat-mdc-header-row {
                display: none;
            }

            .mat-mdc-row, tr {
                flex-direction: column;
                align-items: start;
                min-height: 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }

            .mat-mdc-cell, td {
                display: block;
                width: auto;
                border-bottom: none;
                opacity: 1;
            }

            mat-row {
                padding: $cell-padding;

                mat-cell {
                    padding: 0;

                    &:not(:first-of-type) {
                        margin: $cell-padding 0 0;
                    }
                }
            }

            tr {

                td {
                    padding: $cell-padding $cell-padding 0;

                    &:last-of-type {
                        padding-bottom: $cell-padding;
                    }
                }
            }
        }
    }

    &.table-padding {

        td, th {
            padding: 0.1em 0.5em;
            text-align: left;
        }
    }
}

.button-with-action {

    &:not(.button-submitting) .mat-mdc-progress-spinner {
        display: none;
    }

    &.button-submitting {

        mat-icon {
            display: none;
        }
    }
}

mat-horizontal-stepper .mat-horizontal-content-container {

    @media (max-width: 599px) {
        padding: 0 8px 8px;
    }
}

.cdk-overlay-pane {
    max-width: 95vw !important;
}

.address-form {
    margin-bottom: 16px;

    h3 {
        margin: 1em 0;
    }

    app-address-input {
        padding-left: 16px;
        border-left: 1px solid $label-color;
    }
}

ol.bullet-list,
ul.bullet-list {
    padding-left: 1.5em;
}
