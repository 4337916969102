@use '@angular/material' as mat;

@import 'variables';

@include mat.core();
@include mat.all-component-themes($theme);

@include mat.typography-hierarchy($typography);

.mat-icon.mat-icon-inline {
    // Fixes https://github.com/angular/components/issues/26042
    line-height: 1 !important;
}

.mat-mdc-card-header {
    padding-bottom: 16px !important;
}

mat-tab-group {

    .mat-mdc-tab-body-content {
        padding-top: 8px;
    }

    .mat-mdc-tab-header {
        // Give inactive tabs some sort of border
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
}

.mat-mdc-dialog-container form.mdc-dialog__content {
    // Fixes label of form element being cut off
    $margin: 8px;
    margin-top: -$margin;

    > :first-child {
        margin-top: $margin;
    }
}
